<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="LOADER"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Create New Category">
            <validation-observer ref="createCategoryValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter category name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          name="description"
                          placeholder="Enter description"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                        rules="required"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const categoryModule = createNamespacedHelpers('category')

export default {
  data() {
    return {
      form: {
        name: '',
        description: '',
        image: [],
      },
    }
  },
  computed: {
    ...appModule.mapState(['LOADER']),
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...categoryModule.mapActions(['ADD_CATEGORY']),

    onSubmit() {
      this.$refs.createCategoryValidator.validate().then(async success => {
        if (success) {
          this.UPDATE_LOADER(true)
          const formData = new FormData()
          formData.append('name', this.form.name)
          formData.append('description', this.form.description)
          formData.append('image', this.form.image)
          const resp = await this.ADD_CATEGORY(formData)
          if (resp) {
            this.form.name = ''
            this.form.description = ''
            this.form.image = []
            this.$nextTick(() => {
              this.$refs.createCategoryValidator.reset()
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Category created!',
                icon: 'checkIcon',
                variant: 'success',
                text: 'The category has been created successfully!',
              },
            })
          }
          this.UPDATE_LOADER(false)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-msg {
  margin-top: 4px;
  display: block;
  font-size: 14px;
}
</style>
